import { FuseNavigationItem } from '../../../@fuse/components/navigation';
import { ACCESS_ROUTE } from '../access/route.access';

export const HOME_NAVIGATION: FuseNavigationItem[] = [
    {
        id: ACCESS_ROUTE.WORKSPACE,
        title: 'Home',
        subtitle: 'Home',
        type: 'basic',
        icon: 'mat_outline:home',
        link: '/home/workspace',
    },
    {
        id: ACCESS_ROUTE.DASHBOARD,
        title: 'Dashboard',
        subtitle: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:presentation-chart-bar',
        link: '/home/dashboard',
    },
    {
        id: 'divider-1',
        type: 'divider',
    },
];

export const PARTIES_NAVIGATION: FuseNavigationItem[] = [
    {
        id: ACCESS_ROUTE.PARTY,
        title: 'Parties',
        type: 'collapsable',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: ACCESS_ROUTE.CLIENT,
                title: 'Parties',
                subtitle: 'List Party',
                type: 'basic',
                link: '/contracts/client',
                icon: 'heroicons_outline:users',
            },
            {
                id: 'divider-1',
                type: 'divider',
            },
            {
                id: ACCESS_ROUTE.CEDING,
                title: 'Ceding',
                subtitle: 'List Ceding',
                type: 'basic',
                link: '/contracts/ceding',
                icon: 'mat_outline:admin_panel_settings',
            },
            {
                id: ACCESS_ROUTE.REINSURER,
                title: 'Reinsurance',
                subtitle: 'List Reinsurance',
                type: 'basic',
                link: '/contracts/reinsurance',
                icon: 'mat_outline:add_moderator',
            },
            {
                id: ACCESS_ROUTE.AGENT,
                title: 'Agent',
                subtitle: 'List Agent',
                type: 'basic',
                link: '/contracts/agent',
                icon: 'heroicons_outline:user',
            },
            {
                id: ACCESS_ROUTE.CLIENT_NEW_CLIENT,
                title: 'Create Corporate Client',
                subtitle: 'Create Corporate Client',
                type: 'basic',
                link: '/contracts/client/add?type=Corporate',
                icon: 'heroicons_outline:users',
                hidden: () => { return true; }
            },
            {
                id: ACCESS_ROUTE.CLIENT_NEW_CLIENT,
                title: 'Create Personal Client',
                subtitle: 'Create Personal Client',
                type: 'basic',
                link: '/contracts/client/add?type=Personal',
                icon: 'heroicons_outline:users',
                hidden: () => { return true; }
            },
        ],
    },
];

export const OPERATION_NAVIGATION: FuseNavigationItem[] = [
    {
        id: ACCESS_ROUTE.CONTRACT,
        title: 'Contracts',
        type: 'collapsable',
        icon: 'heroicons_outline:clipboard',
        children: [
            {
                id: ACCESS_ROUTE.FACULTATIVE_CONTRACT,
                title: 'Facultative',
                subtitle: 'List Facultative',
                type: 'basic',
                link: '/contracts/policy-facultative',
                icon: 'mat_outline:admin_panel_settings',
            },
            {
                id: ACCESS_ROUTE.TREATY_CONTRACT,
                title: 'Treaty',
                subtitle: 'List Treaty',
                type: 'basic',
                link: '/contracts/treaty',
                icon: 'mat_outline:assignment',
            },
            {
                id: ACCESS_ROUTE.TREATY_CONTRACT_NEW,
                title: 'Create Treaty',
                subtitle: 'Create Treaty Master Contract',
                type: 'basic',
                link: '/contracts/treaty/add-master-contract',
                icon: 'mat_outline:assignment',
                hidden: () => { return true; }
            },
            {
                id: ACCESS_ROUTE.TREATY_CONTRACT_NEW,
                title: 'Create Treaty',
                subtitle: 'Create Treaty Contract',
                type: 'basic',
                link: '/contracts/treaty/add-contract',
                icon: 'mat_outline:assignment',
                hidden: () => { return true; }
            },
            // {
            //     id: ACCESS_ROUTE.FACILITY_CONTRACT,
            //     title: 'Facility',
            //     subtitle: 'Facility',
            //     type: 'basic',
            //     link: '/contracts/facility',
            //     icon: 'mat_outline:apartment',
            // },
        ],
    },
    {
        id: ACCESS_ROUTE.OPERATION,
        title: 'Operation',
        type: 'collapsable',
        icon: 'mat_outline:assignment',
        children: [
            {
                id: ACCESS_ROUTE.REQUEST_ORDER,
                title: 'Request Order',
                subtitle: 'List Request Order',
                type: 'basic',
                icon: 'mat_outline:ballot',
                link: '/operation/request-order',
            },
            {
                id: ACCESS_ROUTE.REQUEST_ORDER_NEW,
                title: 'Create Request Order',
                subtitle: 'Create Request Order',
                type: 'basic',
                link: '/operation/request-order/add',
                icon: 'mat_outline:assignment',
                hidden: () => { return true; }
            },
            {
                id: ACCESS_ROUTE.QUOTATION,
                title: 'Quotation',
                subtitle: 'List Quotation',
                type: 'basic',
                icon: 'mat_outline:notes',
                link: '/operation/quotation',
            },
            {
                id: ACCESS_ROUTE.QUOTATION_NEW,
                title: 'Create Quotation',
                subtitle: 'Create Quotation',
                type: 'basic',
                link: '/operation/quotation/add',
                icon: 'mat_outline:assignment',
                hidden: () => { return true; }
            },
            {
                id: ACCESS_ROUTE.QUOTATION_NEW,
                title: 'Create Master Quotation',
                subtitle: 'Create Master Quotation',
                type: 'basic',
                link: '/operation/quotation/add-master',
                icon: 'mat_outline:assignment',
                hidden: () => { return true; }
            },
            {
                id: ACCESS_ROUTE.PROPOSAL,
                title: 'Proposal',
                subtitle: 'List Proposal',
                type: 'basic',
                icon: 'mat_outline:file_copy',
                link: '/operation/proposal',
            },
            {
                id: 'TAK_ADA',
                title: 'Endorsement',
                subtitle: 'List Endorsement',
                type: 'basic',
                icon: 'mat_outline:approval',
                link: '/operation/contract-endorsement',
            },
            {
                id: 'divider-1',
                type: 'divider',
            },
            {
                id: ACCESS_ROUTE.COSTING,
                title: 'Costing',
                subtitle: 'List Costing',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: '/operation/costing-management',
            },
            {
                id: ACCESS_ROUTE.COSTING_NEW,
                title: 'Create Costing',
                subtitle: 'Create Costing',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: '/operation/costing-management/add',
                hidden: () => { return true; }
            },
            {
                id: ACCESS_ROUTE.BILLING,
                title: 'Billings',
                subtitle: 'List Billings',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: '/operation/billing',
            },
            {
                id: ACCESS_ROUTE.BILLING_NEW,
                title: 'Create Billing',
                subtitle: 'Create Billing',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: '/operation/billing/add',
                hidden: () => { return true; }
            },
            {
                id: 'TAK_ADA',
                title: 'Claim',
                subtitle: 'List Claim',
                type: 'basic',
                icon: 'mat_outline:edit',
                link: '/operation/claim',
            },
        ],
    },
];

export const FINANCE_NAVIGATION: FuseNavigationItem[] = [
    {
        id: ACCESS_ROUTE.FINANCE_ACCOUNTING,
        title: 'Finance',
        type: 'collapsable',
        icon: 'mat_outline:payments',
        children: [
            {
                id: ACCESS_ROUTE.INVOICE,
                icon: 'heroicons_outline:ticket',
                title: 'Invoice',
                subtitle: 'List Invoice',
                type: 'basic',
                link: '/finance/invoice',
            },
            {
                id: ACCESS_ROUTE.INVOICE_CREATE_NEW,
                icon: 'heroicons_outline:ticket',
                title: 'Create Invoice',
                subtitle: 'Create Invoice',
                type: 'basic',
                link: '/finance/invoice/add',
                hidden: () => { return true; }
            },
            {
                id: 'TAK_ADA',
                title: 'Voucher',
                subtitle: 'List Voucher',
                icon: 'heroicons_outline:ticket',
                type: 'basic',
                link: '/finance/voucher',
                hidden: () => { return true; }
            },
            {
                id: 'TAK_ADA',
                icon: 'heroicons_outline:ticket',
                title: 'Create Voucher',
                subtitle: 'Create Voucher',
                type: 'basic',
                link: '/finance/voucher/add',
                hidden: () => { return true; }
            },
            {
                id: 'divider-1',
                type: 'divider',
            },
            {
                id: ACCESS_ROUTE.PAYMENT,
                title: 'Invoice Payment',
                subtitle: 'List Invoice Payment',
                icon: 'heroicons_outline:currency-dollar',
                type: 'basic',
                link: '/finance/payment',
            },
            {
                id: ACCESS_ROUTE.PAYMENT_CREATE_NEW,
                icon: 'heroicons_outline:ticket',
                title: 'Create Invoice Payment',
                subtitle: 'Create Payment',
                type: 'basic',
                link: '/finance/payment/add',
                hidden: () => { return true; }
            },
            {
                id: ACCESS_ROUTE.RECEIPT,
                title: 'Voucher Payment',
                subtitle: 'List Voucher Payment',
                icon: 'mat_outline:payment',
                type: 'basic',
                link: '/finance/receipt',
                hidden: () => { return true; }
            },
            {
                id: ACCESS_ROUTE.RECEIPT_NEW,
                icon: 'heroicons_outline:ticket',
                title: 'Create Voucher Payment',
                subtitle: 'Create Voucher Payment',
                type: 'basic',
                link: '/finance/receipt/add',
                hidden: () => { return true; }
            },
        ],
    },
    {
        id: ACCESS_ROUTE.ACCOUNTING,
        title: 'Accounting',
        type: 'collapsable',
        icon: 'mat_outline:account_balance',
        children: [
            {
                id: ACCESS_ROUTE.JOURNAL,
                title: 'Journal Entries',
                subtitle: 'List Journal Entries',
                icon: 'mat_outline:list_alt',
                type: 'basic',
                link: '/finance/journal-entries',
            },
            {
                id: ACCESS_ROUTE.JOURNAL_NEW,
                title: 'Create Journal Entries',
                subtitle: 'Create Journal Entries',
                icon: 'mat_outline:list_alt',
                type: 'basic',
                link: '/finance/journal-entries/add',
                hidden: () => { return true; }
            },
            {
                id: ACCESS_ROUTE.LEDGER,
                title: 'Ledger',
                subtitle: 'List Ledger',
                icon: 'mat_outline:book',
                type: 'basic',
                link: '/finance/ledger',
            },
            {
                id: 'divider-1',
                type: 'divider',
            },
            {
                id: ACCESS_ROUTE.PERIOD_MAINTENANCE,
                title: 'Period Maintenance',
                subtitle: 'Period Maintenance',
                icon: 'mat_outline:date_range',
                type: 'basic',
                link: '/finance/period-maintenance',
            },
            // {
            //     id: 'divider-1',
            //     type: 'divider',
            // },
            // {
            //     id: 'TAK_ADA',
            //     title: 'Fixed Assets',
            //     subtitle: 'Fixed Assets',
            //     icon: 'mat_outline:home',
            //     type: 'basic',
            //     link: '/finance/fixed-assets',
            // },
        ],
    },
];

export const REPORTS_NAVIGATION: FuseNavigationItem[] = [
    {
        id: ACCESS_ROUTE.REPORTS,
        title: 'Reports',
        type: 'basic',
        icon: 'mat_outline:description',
        link: '/reports/reports',
        // children: [
        //     {
        //         id: ACCESS_ROUTE.REPORTS_SUBMENU,
        //         title: 'Reports',
        //         subtitle: 'Reports',
        //         type: 'basic',
        //         icon: 'heroicons_outline:document-text',
        //         link: '/reports/reports',
        //     },
        //     {
        //         id: ACCESS_ROUTE.DATA_ANALITICS_SUBMENU,
        //         title: 'Data Analytics',
        //         subtitle: 'Data Analytics',
        //         type: 'basic',
        //         icon: 'heroicons_outline:presentation-chart-line',
        //         link: '/reports/data-analytics',
        //     },
        // ],
    },
];

export const SETTINGS_NAVIGATION: FuseNavigationItem[] = [
    {
        id: ACCESS_ROUTE.SYSTEM,
        title: 'System',
        type: 'collapsable',
        icon: 'mat_outline:settings',
        children: [
            {
                id: ACCESS_ROUTE.BATCH_SUBMISSION,
                title: 'Batch Submission',
                subtitle: 'List Batch Submission',
                icon: 'heroicons_outline:folder',
                type: 'basic',
                link: '/system/batch-submission',
            },
            {
                id: ACCESS_ROUTE.BATCH_SUBMISSION_NEW,
                title: 'Create Batch Submission',
                subtitle: 'Create Batch Submission',
                icon: 'heroicons_outline:folder',
                type: 'basic',
                link: '/system/batch-submission/add',
                hidden: () => { return true; }
            },
            {
                id: ACCESS_ROUTE.TABLE_MAINTENANCE,
                title: 'System Settings',
                subtitle: 'System Settings',
                type: 'basic',
                icon: 'mat_outline:settings',
                link: '/system/table-maintenance',
            },
            {
                id: ACCESS_ROUTE.SYSTEM_SECURITY,
                title: 'System Security',
                type: 'collapsable',
                icon: 'mat_outline:security',
                children: [
                    {
                        id: ACCESS_ROUTE.USER_MAINTENANCE,
                        title: 'Users',
                        subtitle: 'List Users',
                        type: 'basic',
                        link: '/system/users',
                        icon: 'heroicons_outline:users',
                    },
                    {
                        id: ACCESS_ROUTE.USER_MAINTENANCE,
                        title: 'Create Users',
                        subtitle: 'Create User',
                        type: 'basic',
                        link: '/system/users/add',
                        hidden: () => { return true; }
                    },
                    {
                        id: ACCESS_ROUTE.ACCESS_RIGHTS_MAINTENANCE,
                        title: 'Access Rights',
                        subtitle: 'List Access Rights',
                        type: 'basic',
                        link: '/system/roles',
                        icon: 'mat_outline:lock',
                    },
                ],
            },
        ],
    },
];

export const SIDEBAR_ITEMS: FuseNavigationItem[] = [
    ...PARTIES_NAVIGATION,
    ...OPERATION_NAVIGATION,
    ...FINANCE_NAVIGATION,
    ...REPORTS_NAVIGATION,
    ...SETTINGS_NAVIGATION,
];
