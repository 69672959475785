import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HOME_NAVIGATION, SIDEBAR_ITEMS } from '../../constants/sidebar.const';
import { FuseNavigationItem } from '../../../../@fuse/components/navigation';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {

    sidebarItems$: BehaviorSubject<FuseNavigationItem[]> = new BehaviorSubject<FuseNavigationItem[]>(SIDEBAR_ITEMS);
    isOpened$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isHovered$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private authService: AuthService,) {
        authService.accessList$.subscribe(accessList => {
            if (!accessList) {
                return;
            }

            const sidebarList = cloneDeep(SIDEBAR_ITEMS);
            const stack = [];

            sidebarList.forEach(nav => {
                nav.disabled = accessList.findIndex(a => a.objectCode === nav.id) === -1;
                nav.children?.forEach(c => {
                    stack.push(c);
                });
            })

            while (stack.length > 0) {
                const child = stack.pop();
                child.disabled = accessList.findIndex(a => a.objectCode === child.id) === -1;
                child.children?.forEach(c => {
                    stack.push(c);
                });
            }

            const sidebar = [
                ...HOME_NAVIGATION,
                ...sidebarList,
            ];
            this.sidebarItems$.next(sidebar);
        });
    }

    toggle() {
        this.isOpened$.next(!this.isOpened$.value);
    }

    setOpened(value: boolean) {
        this.isOpened$.next(value);
    }


}
